@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Outfit", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gradient-custom {
  /* background: rgb(225, 225, 1); */
  background: linear-gradient(90deg, #92c309 0%, #64c312 100%);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #32c100;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.top-2rem {
  top: 2rem;
}

/* Add this CSS code */
@media only screen and (max-width: 950px) and (orientation: landscape) {
  .hide-on-mobile-landscape {
    display: none;
  }
}

.react-tel-input .country-list .country-name
{
  color: black;
  font-weight: bold;
}
